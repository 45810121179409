import React from 'react';

// Components
import Title from '@Components/Shared/Title';
import ProcessItem from '@Components/Layouts/Home/ProcessItem';

// Assets
import { ReactComponent as imageProcess1 } from '@Assets/Icons/process-1.svg';
import { ReactComponent as imageProcess2 } from '@Assets/Icons/process-2.svg';
import { ReactComponent as imageProcess3 } from '@Assets/Icons/process-3.svg';
import { ReactComponent as WaveDashed } from '@Assets/Icons/wave-dash.svg';
import { ReactComponent as WaveDashedLarge } from '@Assets/Icons/wave-dash-large.svg';
import Link from '@Components/Shared/Buttons/Link';

const index = () => {
	return (
		<section className='home__process'>
			<div className='container'>
				<div className='flex flex-col items-center'>
					<Title>یک پروسه، چهار توقفگاه</Title>
					<span className='wave-up'>
						<WaveDashedLarge />
					</span>
					<div className='home__process--steps flex flex-col'>
						<ProcessItem
							title=' ثبت نام و ایجاد حساب کاربری'
							Icon={imageProcess1}
							data-count='1'
						>
							در گام اول لازم است برای ایجاد حساب کاربری اقدام کنید.
						</ProcessItem>
						<WaveDashed />
						<ProcessItem
							title='خرید دوره آموزشی و سپری کردن کلاس‌ها'
							Icon={imageProcess2}
							data-count='2'
						>
							در گام دوم می‌توانید نسبت به خرید دوره های آموزشی مدنظر خود اقدام کنید.

						</ProcessItem>
						<WaveDashed />
						<ProcessItem
							title='انجام پروژه‌ها، ارزیابی مهارت‌ها و رفع ایرادات'
							Icon={imageProcess3}
							data-count='3'
						>
							در گام سوم شما به انجام پروژه‌های عملی می‌پردازید. مهارت‌های شما توسط اساتید ارزیابی می‌شوند و شما باید ایرادات خود را رفع کنید.

						</ProcessItem>
						<WaveDashed />
						<ProcessItem
							title='ارزیابی پایانی و کسب گواهی پایان دوره'
							Icon={imageProcess1}
							data-count='4'
						>
							در گام نهایی با شرکت در آزمون دوره، می‌توانید «گواهی پایان دوره کدآموز» دریافت کنید
						</ProcessItem>
						<WaveDashedLarge />
					</div>
					<div className='home__process--action'>
						<Link to='/courses' type='primary'>
							همه دوره ها
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};
export default index;
