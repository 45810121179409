import React from 'react';

const index = ({ children }) => {
	return (
		<div className='title__box'>
			<h2>{children}</h2>
		</div>
	);
};
export default index;
 