
// export const API_URL = "http://88.218.17.104:9002";
export const USER_URL = "https://codeamooz.com";
export const API_URL = "https://finalapi.codeamooz.com";
export const TEAChER_URL = "https://teacher.codeamooz.com";
export const HOME_URL = "codeamooz.com";

// export const API_URL = "https://finalapi.codeamooz.com";
// export const TEAChER_URL = "https://teacher.codeamooz.com";
// export const HOME_URL = "codeamooz.com";

export const TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjMyNTc4NDUwLCJqdGkiOiJjN2ZjYmViZDI4NjY0ODlhOTZkMzUzNTE1ZTVlZDNmMyIsInVzZXJfdXNlcm5hbWUiOiJyb290In0.tfeUIAhtZclK2YO82uH2s-mOV62vBfIkh2TuETtS3t0";
export const PAGE_SIZE = 5;
