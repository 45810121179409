import React from "react";
import icon from "../../../../Assets/Images/Pic/empthy chat.svg";
const EmptyChatroom = () => {
  return (
    <div className="EmptyChatroom center">
      <img src={icon} alt="empthy-chatroom" />
    </div>
  );
};

export default EmptyChatroom;
