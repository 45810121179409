import React,{useEffect} from "react";
import RulesContainer from "@Components/Layouts/Rules/RulesContainer";

const Rules = () => {

  return (
    <div className="container">
      <RulesContainer />
    </div>
  );
};

export default Rules;
