import React from "react";
import FavoritesContainer from "@Components/Layouts/Favorites/FavoritesContainer";

const Favorites = () => {
  return (
    <div className="container" style={{ marginTop: "12rem" }}>
      <FavoritesContainer />
    </div>
  );
};

export default Favorites;
