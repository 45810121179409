import React from "react";
import TeacherResumeIndex from "@Components/Layouts/TeacherResume/TeacherResumeIndex";

const TeacherResume = () => {
    return (
        <>
            <TeacherResumeIndex />
        </>
    );
};

export default TeacherResume;
