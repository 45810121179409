import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import { Badge } from 'antd';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Propfile from './ProfileMenu/Propfile';

// Components
import Register from '@Components/Layouts/Register/Register';
import IconBtn from '../Buttons/IconBtn';
import Button from '../Buttons/Button';

// Hooks
import UseScrollAppbar from './UseScrollAppbar';

// Assets
import logo from '@Assets/Logo/logo.svg';
import { ReactComponent as ShoppingCartIcon } from '@Assets/Icons/Buy.svg';
import useMediaQuery from '@App/Hooks/useMediaQuery';
import { useAuth } from '@App/Context/authContext';
import Modal from '@Components/Shared/Modal/Modal';

gsap.registerPlugin(ScrollTrigger);

// constants
const menuList = [
	{
		url: '/courses',
		title: ' دوره ها ',
	},
	{
		url: '/coWorkers',
		title: 'همکاران و اساتید',
	},
	{
		url: '/faq',
		title: 'پرسش و پاسخ',
	},
	{
		url: '/contact-us',
		title: 'ارتباط با ما',
	},
];

const Appbar = () => {
	const [isModalVisible, setModalVisible] = useState(false);
	const [isOpenMenu, setOpenMenu] = useState(false);

	// const { sticky } = UseScrollAppbar();
	const navigate = useNavigate();
	const isTablet = useMediaQuery('(max-width: 768px)');

	const handleModalVisible = () => {
		setModalVisible((prev) => !prev);
	};
	const [modal, setModal] = useState(false);
	const handleModalShow = () => {
		setModal((prev) => !prev);
	};
	const handleClose = () => {
		setOpenMenu(false);
		setModal(true);
		// handleModalShow();
	};

	const logout = () => {
		authDispatch({ type: 'LOGOUT' });
		navigate('/', { replace: true });
	};
	useEffect(() => {
		ScrollTrigger.create({
			trigger: '.appbar',
			toggleClass: 'appbar-scrolled',
			start: 'top -100',
			end: 99999,
			// end: () => 'bottom+=' + document.querySelector('.footer').offsetTop,
		});
	}, []);
	const handleToggleMenu = () => {
		if (isTablet) {
			setOpenMenu((prev) => !prev);
			isOpenMenu
				? document.body.classList.remove('scrolling-effect')
				: document.body.classList.add('scrolling-effect');
		}
	};
	const { token, authDispatch } = useAuth();

	return (
		<>
			<div className='appbar'>
				<div className='container flex appbar__container'>
					<NavLink to='/' className='z-10'>
						<div className='appbar__logo d-flex gap-x-7'>
							<div className='appbar__logo--img '>
								<img src={logo} alt='CodeAmooz' />
							</div>
							<h2 className='appbar__logo--title'>CODEAMOOZ</h2>
						</div>
					</NavLink>
					<div
						className={classNames('appbar__layout d-flex flex-grow', {
							'open-menu': isOpenMenu,
						})}
					>
						<nav className='appbar__menu flex'>
							{menuList.map(({ url, title }) => (
								<NavLink
									key={url}
									to={url}
									className='appbar__menu--link'
									onClick={() => handleToggleMenu()}
								>
									<span>{title}</span>
								</NavLink>
							))}
						</nav>
						<div className='appbar__action d-flex gap-x-7'>
							{token && <div className=''>
								<IconBtn
									title='سبد خرید'
									classes='appbar__action--cart'
									icon={
										<Badge
											size='small'
											// count={cartData?.cart_nums}
											className='appbar__action--badge'
										>
											<ShoppingCartIcon />
										</Badge>
									}
									onClick={() => navigate('/shopping-card')}
								/>
							</div>}
							{token ? (
								<div className='d-flex-align Menu_actions--profile'>
									<div className={classNames('profile__image')}>
										<Propfile />
									</div>
								</div>
							) : (
								<Button type='primary' onClick={handleModalVisible}>
									ورود / ثبت نام
								</Button>
							)}
						</div>
					</div>
					<IconBtn
						classes={classNames('appbar__menu--btn', {
							open: isOpenMenu,
						})}
						icon={
							<>
								<span></span>
								<span></span>
								<span></span>
							</>
						}
						onClick={handleToggleMenu}
					/>
				</div>
			</div>
			{modal && (
								<Modal
									className='ExitModal'
									visible={modal}
									onCancel={handleModalShow}
								>
									<div className='ExitModal__back'>
										<p className='mb-12'>آیا از خروج مطمئن هستید؟</p>
										<div className='d-flex-space'>
											<Button onClick={logout}>بله</Button>
											<Button
												onClick={handleModalShow}
												type='primary'
											>
												خیر
											</Button>
										</div>
									</div>
								</Modal>
							)}
			<Register visible={isModalVisible} onCancel={handleModalVisible} />
		</>
	);
};
export default Appbar;
