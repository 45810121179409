import React from 'react';
import Header from './Header';
import Introduction from './Introduction';
import Process from './Process';
import Courses from './Courses';

const Home = () => {
	return (
		<>
			<Header />
			<Introduction />
			<Process />
			<Courses />
		</>
	);
};
export default Home;
