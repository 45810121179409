import React, { useState, useEffect } from "react";
import { languages } from "@Components/Shared/Codeeditor/LAnBox";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import UseCopyToClipboard from "@App/Hooks/UseCopyToClipboard";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";
import AceEditor from "react-ace";
// import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../../constants";
import { useAuth } from "../../../Context/authContext";
import { toast } from "react-toastify";
import Modal from "@Components/Shared/Modal/Modal";
import Button from "@Components/Shared/Buttons/Button";
import { Link } from "react-router-dom";

function QuizCodeeditorWithRun(props) {
  languages.map(
    (lang) =>
      lang === props.lan &&
      require(`ace-builds/src-noconflict/mode-${lang}`) &&
      require(`ace-builds/src-noconflict/snippets/${lang}`)
  );
  const [value, setValue] = useState(props.value);
  const [test, setTest] = useState("");
  const [quizeResult, setQuizeResult] = useState();
  const [number, setNumber] = useState(0);
  const [res, setRes] = useState("");
  const [errs, setErrs] = useState("");
  // const [info, setInfo] = useState();
  // const [info1, setInfo1] = useState("");
  const [data, setData] = useState("");
  const [isCopied, handleCopy] = UseCopyToClipboard(3000);
  const [load, setLoad] = useState(false);
  const [btn, setBtn] = useState(1);
  const [numberComplit, setNumberComplit] = useState(0);
  const { token } = useAuth();
  const [modal, setModal] = useState(false);
  const handle = useFullScreenHandle();

  const handleModalVisible = () => {
    setModal(false);
  };
  console.log("props.nextseson", props.nextseson);
  const handleModalShow = (uuid, lock) => {
    setModal(true);
  };
  const QuizPlayGround = useAxios({
    url: `${API_URL}/CompilerService/v2/quiz/playground/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizPlayGround", res.data);
        // setInfo(res.data);
        res.data.compiler_stdout ? setBtn(1) : setBtn(2);
        setRes(res.data.compiler_stdout);
        res.data.compiler_stderr
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs(res.data.compiler_stderr);
        setLoad(false);
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });
  const QuizSendToServer = useAxios({
    url: `${API_URL}/CompilerService/v3/quiz/send_to_server/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizSendToServer", res.data);
        // setInfo1(res.data);
        setQuizeResult(res.data);
        handleModalShow();

        setNumber(res.data.compile_result);
        res.data.compiler_stderr.length > 0 ? setBtn(2) : setBtn(1);
        setRes(res.data.compiler_stdout);
        res.data.compiler_stderr
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs(res.data.compiler_stderr);
        setLoad(false);
        console.log(res.data.compiler_stderr.length);

        if (res.data.compile_result === 100) {
          setNumberComplit(res.data.compile_result);
          postPassContent.reFetch();
        } else {
          setNumber(res.data.compile_result);
          setLoad(false);
        }
      }
      if (err) {
        console.log(err.response);
        if (err.response.status==500){
          toast.error("خطایی رخ داد. دوباره امتحان کنید");
        }
        setLoad(false);
      }
    },
  });
  const postPassContent = useAxios({
    url: `${API_URL}/PassService/${props.contentId}/`,
    method: "POST",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("postPassContent", res.data);
        setNumber(numberComplit);
        setLoad(false);
      }
      if (err) {
        console.log(err.response);
        setNumber(numberComplit);
        setLoad(false);
      }
    },
  });
  const handleSend = () => {
    if (token) {
      setData({
        submissions: {
          question_id: props.quizId,
          input: test,
          source: value,
        },
      });
      setLoad(true);
      QuizPlayGround.reFetch();
    } else {
      toast.error("ابتدا وارد سایت شوید");
    }
  };
  // ismycoursebol
  // ispreview
  const handleInputSend = () => {
    if (!token) {
      toast.error("برای پاسخ به آزمون اول به سایت وارد شوید");
    } else if (props.ispreview && !props.ismycoursebol) {
      toast.error("برای پاسخ به آزمون در دوره ثبت نام کنید");
    } else {
      setData({
        submissions: {
          question_id: props.quizId,
          source: value,
          // input: test,
        },
      });
      setLoad(true);
      QuizSendToServer.reFetch();
    }
    if (!props.ispreview) {
      setData({
        submissions: {
          question_id: props.quizId,
          source: value,
          // input: test,
        },
      });
      setLoad(true);
      QuizSendToServer.reFetch();
    }
  };

  function onChange(newValue) {
    setValue(newValue);
  }

  function onChange1(newValue) {
    setTest(newValue);
  }

  const override = css`
    display: block;
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `;
  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([value], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${props.name}.${props.lang}`;
    document.body.appendChild(element);
    element.click();
  };
  console.log("props.courseId", props.courseId);
  return (
    <FullScreen handle={handle} enabled={handle}>
      <div
        className={
          handle.active
            ? " CodeeditorWithRun w-full h-full"
            : "CodeeditorWithRun w-full"
        }
      >
        {props.lan && (
          <>
            <div
              className={handle.active ? " w-full h-full" : "container"}
              style={{ position: "relative " }}
            >
              <div
                className={
                  handle.active
                    ? " CodeeditorWithRun__codeeditor  h-full"
                    : "CodeeditorWithRun__codeeditor"
                }
              >
                <div>
                  <div
                    className="CodeeditorWithRun__codeeditor-btnBox"
                    style={
                      handle.active ? { padding: "1rem 0" } : { padding: "0" }
                    }
                  >
                    <p>{`${props.name}.${
                      props.lan === "c_cpp" && props.lang === "c"
                        ? "c"
                        : props.lan === "c_cpp" && props.lang === "c_cpp"
                        ? "cpp"
                        : props.lan
                    }`}</p>
                    <div className="d-flex ">
                      {handle.active ? (
                        <button
                          className="codeeditorBox__fulScreen"
                          onClick={handle.exit}
                        ></button>
                      ) : (
                        <button
                          className="codeeditorBox__fulScreen"
                          onClick={handle.enter}
                        ></button>
                      )}
                      <button
                        className="CodeeditorWithRun__codeeditor-btncopy"
                        onClick={handleDownload}
                      >
                        ذخیره کدها
                      </button>
                      <button
                        className="CodeeditorWithRun__codeeditor-btncopy"
                        onClick={() => handleCopy(value)}
                      >
                        {isCopied ? (
                          <div className="d-flex">
                            <i className="fas fa-check"></i>
                            کپی شد
                          </div>
                        ) : (
                          <div className="d-flex">
                            {" "}
                            <i className="far fa-copy"></i>
                            کپی
                          </div>
                        )}
                      </button>
                      <button
                        className={
                          load
                            ? "CodeeditorWithRun__codeeditor-btndonedis"
                            : "CodeeditorWithRun__codeeditor-btndone"
                        }
                        onClick={handleSend}
                        disabled={load}
                      >
                        <i className="fas fa-play"></i>
                        اجرای کد
                      </button>
                      <button
                        className={
                          load
                            ? "CodeeditorWithRun__codeeditor-btndonedis"
                            : "CodeeditorWithRun__codeeditor-btndone CodeeditorWithRun__codeeditor1-btndone"
                        }
                        onClick={handleInputSend}
                        disabled={load}
                      >
                        <i className="fas fa-play"></i>
                        ارسال پاسخ
                      </button>
                    </div>
                  </div>
                  <AceEditor
                    mode={props.lan}
                    theme="monokai"
                    value={value}
                    onChange={onChange}
                    width="100%"
                    height={handle.active ? "100%" : "64.1vh"}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                      fontSize: "1.5rem",
                    }}
                  />
                </div>
                <div   style={
                      handle.active ? { height: "100%" } : { height: "64.1vh" }
                    }>
                  <div className="CodeeditorWithRun__codeeditor-btnBox2"    style={
                        handle.active
                          ? { padding: "2.2rem 0 0 0" }
                          : { padding: "0.35rem 0 0 0" }
                      }>
                    <div className="d-flex">
                      <button
                        className={
                          btn === 1
                            ? "CodeeditorWithRun__activbtn"
                            : "CodeeditorWithRun__disactivbtn1"
                        }
                        value={1}
                        onClick={() => setBtn(1)}
                      >
                        Result
                      </button>
                      <button
                        className={
                          btn === 2
                            ? "CodeeditorWithRun__activbtn"
                            : "CodeeditorWithRun__disactivbtn1"
                        }
                        value={2}
                        onClick={() => setBtn(2)}
                      >
                        Errors
                      </button>
                    </div>

                    {!props.code && (
                      <div className="d-flex quizNumber__part">
                        <p>:نمره از 100 </p>
                        <p
                          className={
                            number < 100.0
                              ? "faildquiz"
                              : number === 100.0
                              ? "goodquiz"
                              : "passquiz"
                          }
                        >
                          {number}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="result-codeeditor" style={{ height: "50%" }}>
                    <AceEditor
                      mode={props.lan}
                      theme="monokai"
                      value={btn === 1 ? res : errs}
                      width="100%"
                      height="100%"
                      name="UNIQUE_ID_OF_DIV"
                      readOnly
                      editorProps={{ $blockScrolling: true }}
                      highlightActiveLine={false}
                      showGutter={false}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: false,
                        fontSize: "1.5rem",
                      }}
                    />
                  </div>
                  <div
                    className="CodeeditorWithRun__TestBox"
                    style={{ height: "50%" }}
                  >
                    <div className="CodeeditorWithRun__codeeditor-btnBox1">
                      <p>Test</p>
                    </div>{" "}
                    <AceEditor
                      mode={props.lan}
                      theme="monokai"
                      value={test}
                      onChange={onChange1}
                      readOnly={load}
                      width="100%"
                      height="100%"
                      name="UNIQUE_ID_OF_DIV"
                      editorProps={{ $blockScrolling: true }}
                      highlightActiveLine={false}
                      showGutter={false}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: false,
                        fontSize: "1.5rem",
                      }}
                    />
                  </div>
                </div>
              </div>
              <RiseLoader
                color="#0dca78"
                loading={load}
                size={30}
                css={override}
              />
            </div>
          </>
        )}
      </div>
      {quizeResult && (
        <Modal
          className="ExitModal"
          visible={modal}
          onCancel={handleModalVisible}
        >
          <div className="ExitModal__back ">
            <div className=" flex items-center justify-center">
              <h3 className=" ml-6">نمره شما:</h3>
              <h3>{quizeResult.compile_result}</h3>
            </div>
            <p className="mb-12">
              {quizeResult.compiler_stdout === "Accepted"
                ? "آزمون پاس شد"
                : "دوباره تست کنید"}
            </p>
            <div className="d-flex-space">
              <Button>
                {quizeResult.compile_result === "100" ? (
                  <Link
                    to={`/coursecontent`}
                    state={{
                      id: props.courseId,
                    }}
                    className="flex items-center "
                  >
                    صفحه ی دوره
                  </Link>
                ) : (
                  <Link
                    to={`/coursecontent`}
                    state={{
                      id: props.courseId,
                    }}
                    className="flex items-center "
                  >
                    صفحه ی دوره
                  </Link>
                )}
              </Button>
              <Button
                onClick={handleModalVisible}
                type="primary"
                classes={"mr-8"}
              >
                برگشت به آزمون
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </FullScreen>
  );
}

export default QuizCodeeditorWithRun;
