export const faqData = [
    {heading : "1.کدآموز چه خدماتی را ارائه می‌دهد؟",id:1, text:"سامانه کدآموز با ارائه جدیدترین و تازه‌ترین مطالب و ابزارهای برنامه‌نویسی به دانشجوان و علاقمندان این رشته، از آن‌ها حمایت می‌کند تا بتوانند در دنیای فناوری اطلاعات، توانایی‌هایی خود را بهبود ببخشند و مطالب متنوع و جدیدی فراگیرند. این پلتفرم به دانشجوان امکان دسترسی آسان به منابع آموزشی متنوع و تعامل با جوامع برنامه‌نویسی را فراهم می‌کند تا از انجمن‌های مشترک بهره‌برند و تجربیات خود را با دیگران به اشتراک بگذارند. دوره‌های آموزشی سامانه کدآموز توسط اساتید و متخصصان با تجربه ارائه می‌شوند و به دانشجوان این فرصت را می‌دهند تا از دانش و تجربه‌ی آن‌ها بهره‌برند. این دوره‌ها با رویکرد عملی و پروژه‌محور طراحی شده‌اند تا دانشجویان بتوانند مهارت‌های خود را در محیط‌های واقعی تمرین کرده و به بهبود توانایی‌های برنامه‌نویسی خود بپردازند."},
    {heading : "2.آموزش تعاملی به چه معناست؟",id:2, text:" علاوه بر آموزش، سامانه کدآموز امکان ارزیابی تعاملی را به دانشجوان می‌دهد. ارزیابی‌ها به صورت تمرین‌ها، پروژه‌های عملی، آزمون‌ها و تکالیف ویژه ارائه می‌شوند تا دانشجوان بتوانند مهارت‌های خود را در عمل آزمایش کرده و پیشرفت کنند.    "},
    {heading : "3. در صورت سپری کردن دوره، آیا گواهی پایان دوره به من تعلق می‌گیرد؟",id:3, text:"در «کدآموز»، ما به اهمیت توسعه حرفه‌ای برنامه‌نویسان اعتقاد داریم. به همین دلیل، برای افرادی که دوره‌های ما را با موفقیت تکمیل می‌کنند و پروژه‌های عملی با کیفیت ارائه می‌دهند، فرصتی ویژه برای دریافت گواهی پایان دوره ارائه می‌کنیم.  این گواهی‌نامه‌ها تحت نظر سازمان فنی و حرفه‌ای معتبر و همچنین وزارت علوم تایید می‌شوند. این امر به شما اطمینان می‌دهد که مهارت‌ها و دانشی که در دوره‌های ما به دست می‌آورید، به شکل رسمی تایید شده است و می‌تواند به افزایش اعتبار و فرصت‌های شغلی شما کمک کند. با این گواهی‌نامه‌ها، شما می‌توانید به عنوان یک برنامه‌نویس حرفه‌ای در صنعت فناوری اطلاعات شناخته شوید و به پیشرفت حرفه‌ی خود بیافزایید.  "},
    {heading : "4. شرط دریافت گواهی پایان دوره چیست؟",id:4, text:"دریافت گواهی پایان دوره در «کدآموز» به قدم بسیار مهم بستگی دارد. قدم اول آن است کهشما باید تمام مراحل دوره آموزشی را با موفقیت سپری کرده و تمام مطالب و مفاهیم آموزشی را به خوبی فهمیده باشید.قدم دوم، شما باید پروژه‌های عملی که در طول دوره به شما ارائه می‌شوند، با سطح کیفیت و عملکرد مطلوبی انجام داده و تایید اساتید دوره را دریافت کنید.در نهایت، پس از سپری کردن  این دو مرحله اساسی است که گواهی پایان دوره، به عنوان تاییدی از مهارت‌ها و دانش شما در دنیای برنامه‌نویسی صادر می‌شود. "},
    {heading : "5.  این گواهی پایان دوره چه ارزشی دارد؟",id:5, text:"بله، دریافت مدرک از سایت بسیار ارزشمند است و می‌تواند به افراد در بهبود فرصت‌های شغلی و اعتبار حرفه‌ای کمک کند، اما ارزش اصلی این دوره‌های آموزشی در این است که بتوانید مهارت‌های آموخته شده را در پروژه‌های عملی به کار گیرید. از آنجا که دوره‌های «کدآموز» از رویکرد عملی و پروژه‌محور برخوردارند، به دانشجویان کمک می‌کند تا تجربه‌ی عملی کسب ‌کنند و قادر به حل مسائل و پروژه‌های واقعی شوند. بنابراین ارزش واقعی این دوره‌ها در ارتقاء مهارت‌های عملی برنامه‌نویسی و توانایی اجرای پروژه‌ها در دنیای واقعی است.    "},
    {heading : "6.در صورت بدست نیاوردن حدقل امتیاز مورد نیاز برای قبولی در دوره، آیا باز میتوانم ارزیابی شوم؟",id:6, text:"بله، امکان ارزیابی مجدد برای تمام دانش پذیران فراهم است تا با رفع نقاط ضعف خود به سطح علمی مدنظر دست یابند."},
    {heading : "7. آیا امکان دانلود فایل دوره‌های آموزشی وجود دارد؟",id:7, text:"بله پس از ایجاد و تکمیل ثبت نام در سایت امکان دانلود و دسترسی به فایل و محتواهای آموزشی فراهم هست.     "},
    {heading : "8. برای برنامه نویس شدن، در ابتدا به چه سیستم و تجهیزاتی نیاز دارم؟    ",id:7, text:"باتوجه به گستردگی زبان های برنامه نویسی، پاسخ دقیق به این پرسش متناسب با زبانی برنامه نویسی که به دنبال فراگیری آن هستید مشخص خواهد شد.     "},

];