import React, { useEffect } from "react";

function Deatil() {
  useEffect(() => {}, []);
  return (
    <div className="Deatil">
      <p className="Deatil__txt leading-loose">
        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده
        از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و
        سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
        متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه
        درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با
        نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان
        خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید
        داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان
        رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات
        پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
      </p>
      <div className="Deatil__listBox">
        <p className="Deatil__title font-bold"> زمینه های همکاری</p>
        <ul className="Deatil__list">
          <li className="Deatil__item">و سه درصد گذشته حال و آینده</li>
          <li className="Deatil__item">
            شناخت فراوان جامعه و متخصصان را می طلبد
          </li>
          <li className="Deatil__item">و سه درصد گذشته حال و آینده، شناخت</li>
          <li className="Deatil__item">
            و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد
          </li>
        </ul>
      </div>
      <div className="Deatil__listBox">
        <p className="Deatil__title font-bold">مزایای همکاری برای ما و شما</p>
        <ul className="Deatil__list">
          <li className="Deatil__item">
            حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد
          </li>
          <li className="Deatil__item">
            و سه درصد گذشته حال و آینده، شناخت فراوان جامعه
          </li>
          <li className="Deatil__item">و سه درصد گذشته حال و آینده، شناخت</li>
          <li className="Deatil__item">
            شناخت فراوان جامعه و متخصصان را می طلبد{" "}
          </li>
        </ul>
      </div>
      <div className="Deatil__listBox">
        <p className="Deatil__title font-bold">و در انتها</p>
        <p className="Deatil__txt leading-loose">
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده
          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و
          سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
          متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه
          درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با
          نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان
          خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید
          داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به
          پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی
          سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
        </p>
      </div>
    </div>
  );
}

export default Deatil;
