import React from "react";
import ProfileForm from "@Components/Layouts/profile/ProfileForm";

const Profile = () => {
  return (
    <div>
      <ProfileForm />
    </div>
  );
};

export default Profile;
