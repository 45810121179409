import React, { useState } from "react";
import CourseTopicContainer from "@Components/Layouts/Course topic/CourseTopicContainer";

const Coursetopic = () => {
  return (
    <div className="container mt-40">
      <CourseTopicContainer />
    </div>
  );
};

export default Coursetopic;

{
  /* <BasicInfoForm nextStep={nextStep} prevStep={prevStep} */
}
